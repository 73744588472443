<template>
  <!--  <div class="text-md md:text-lg mb-6 text-indigo-500 text-left">Pick a date, then a time you would like to come.<br/>We will confirm the availability of that time.</div>-->

  <div class="flex flex-col md:flex-row md:space-x-6">
    <!--  <div>{{ selectingStartDateTime }}</div>-->
    <div class="md:flex-1 flex flex-col">
      <label class="flex flex-row text-gray-500 text-sm font-bold mb-2 text-justify" for="date">
        Pick a date &nbsp;
        <DateInfo :selectingDate="selectingDate"/>
      </label>
      <input id="date" type=date min="{{ minDate }}" required v-model="date" @change="handleDateChange" class="border border-gray-400 rounded-xl py-2 text-lg px-4"/>

      <!--      <div class="w-96 px-2">-->
      <!--        <VDatePicker mode="date" v-model="date" expanded borderless @change="handleDateChange"/>-->
      <!--      </div>-->
    </div>


    <div class="md:flex-1 flex flex-col mt-2">
      <div>
        <label class="block text-gray-500 text-sm font-bold mb-3 mt-6 md:mt-0 text-justify" for="date">
          Pick a time
        </label>

        <!--        <div class="flex flex-row">-->
        <!--          <button class="h-12 w-52 bg-indigo-500 text-white font rounded-xl px-4 mb-3 mx-2 text-sm" v-if="isAnyAvailableTime" type="button" @click="switchAnyAvailableTime">-->
        <!--            Any available time-->
        <!--          </button>-->
        <!--          <button class="h-12 w-52 bg-gray-100 text-black font rounded-xl px-4 mb-3 mx-2 text-sm" v-else type="button" @click="switchAnyAvailableTime">-->
        <!--            Any available time-->
        <!--          </button>-->
        <!--        </div>-->

        <!--        <div class="text-sm text-justify" v-if="isAnyAvailableTime">-->
        <!--          We will call you to confirm your appointment.-->
        <!--        </div>-->

        <div class="text-sm text-justify" v-if="fetchingAppointments">
          Loading...
        </div>


        <div class="flex flex-row flex-wrap text-sm" v-else>
          <div v-for="(time, index) in timeSlots" :key="index">
            <!--            <div v-if="index < 12">-->
            <button v-if="didPassCurrentTime(time) || !isStoreBusinessHours(time) || isStoreTimeOff(time)[0] || !isStaffWorkingHour(time) || isStaffTimeOff(time)[0] || !isStaffAvailable(time) || !isEnoughTime(time)" class="w-[102px] h-10 mx-2 md:mr-4 mb-4 pb-3 pt-1 bg-gray-50 rounded-xl">
              <div class="flex flex-col">
                <div class="h-3 text-gray-300">{{ time }}</div>
                <div class="h-2 text-red-400 text-[10px]" v-if="!isStoreBusinessHours(time)">Close</div>
                <div class="h-2 text-red-400 text-[8px]" v-else-if="isStoreTimeOff(time)[0]">Close for {{ isStoreTimeOff(time)[1] }}</div>
                <div class="h-2 text-red-400 text-[10px]" v-else-if="didPassCurrentTime(time)">Not available</div>
                <div class="h-2 text-red-400 text-[10px]" v-else-if="!isStaffWorkingHour(time)">{{ this.selectingStaffs[0]['first_name'] }} off</div>
                <div class="h-2 text-red-400 text-[8px]" v-else-if="isStaffTimeOff(time)[0]">{{ this.selectingStaffs[0]['first_name'] }} off</div>
                <div class="h-2 text-red-400 text-[8px]" v-else-if="!isStaffAvailable(time)">{{ this.selectingStaffs[0]['first_name'] }} not avail</div>
                <div class="h-2 text-amber-500 text-[8px]" v-else-if="!isEnoughTime(time)">Not enough time</div>
                <div class="h-2 text-red-400 text-[10px]" v-else>Not available</div>
              </div>
            </button>
            <button v-else-if="selectingTime === time" @click="handleButtonClick(time)" class="w-[102px] h-10 mx-2 md:mr-4 mb-4 p-2 bg-indigo-500 text-white rounded-xl md:hover:border md:hover:border-indigo-800">{{ time }}</button>
            <button v-else @click="handleButtonClick(time)" class="w-[102px] h-10 mx-2 md:mr-4 mb-4 p-2 bg-gray-100 rounded-xl md:hover:border md:hover:border-indigo-500">{{ time }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import { ref } from 'vue';
import DateInfo from '@/views/projects/rocket-tickets/appointment/book-appointment/datetime-tab/DateInfo.vue';

// const date = ref(new Date());

export default {
  name: 'DateTimeTab',
  components: {
    DateInfo,
  },
  data() {
    return {
      date: ref(new Date()),
      minDate: '',
      time: '',
      timeSlots: [],
      testStartTime: new Date,

      staffTimeOffStart: new Date(),
      staffWorkingHourEnd: new Date(),
    };
  },
  props: {
    selectingDate: String,
    selectingTime: String,
    selectingStaffs: Array,
    selectingServices: Array,
    selectingStartDateTime: Date,
    isAnyAvailableTime: Boolean,
    store: {
      type: Object,
      required: true,
    },
    time_offs: {
      type: Object,
      required: true,
    },
    appointments: {
      type: Object,
      required: true,
    },
    fetchingAppointments: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.generateTimeSlots();
    this.date = this.selectingDate;

    const timestamp = Date.now();

    const dateObject = new Date(timestamp);

    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1)
        .padStart(2, '0'); // Month is zero-based
    const day = String(dateObject.getUTCDate())
        .padStart(2, '0');

    this.minDate = `${year}-${month}-${day}`;

    // console.log(this.appointments);
  },
  watch: {
    date: function (newDate, oldDae) {
      // const year = dateObject.getUTCFullYear();
      // const month = String(dateObject.getUTCMonth() + 1)
      //     .padStart(2, '0'); // Month is zero-based
      // const day = String(dateObject.getUTCDate())
      //     .padStart(2, '0');
      // oldDae = `${year}-${month}-${day}`

      this.handleDateChange();
    }
  },
  methods: {
    generateTimeSlots() {
      const startTime = new Date();
      startTime.setHours(8, 30, 0); // Set start time to 9:30 AM

      const endTime = new Date();
      endTime.setHours(20, 0, 0); // Set end time to 7:00 PM

      const increment = 15 * 60 * 1000; // 15 minutes in milliseconds

      while (startTime <= endTime) {
        const formattedTime = this.formatTime(startTime);

        this.timeSlots.push(formattedTime);

        startTime.setTime(startTime.getTime() + increment);
      }
    },

    isAvaliableTime(time) {
      let result = true;
      if (this.didPassCurrentTime(time) || !this.isStoreBusinessHours(time) || this.isStoreTimeOff(time)[0] || !this.isStaffWorkingHour(time) || this.isStaffTimeOff(time)[0] || !this.isStaffAvailable(time) || !this.isEnoughTime(time)) {
        result = false;
      }

      return result;
    },

    getJSTime(shortTime) {
      // shortTime is in this format '9:00 AM'
      // Converts to JS DateTime object
      let tempTime = new Date();
      tempTime.setDate(+`${this.selectingDate.split('-')[2]}`);
      tempTime.setMonth(+`${this.selectingDate.split('-')[1]}` - 1);
      tempTime.setFullYear(+`${this.selectingDate.split('-')[0]}`);
      tempTime.setHours(+`${shortTime.split(':')[0]}`, +`${shortTime.split(':')[1]}`, 0);

      return tempTime;
    },

    convertDeterminingTimeToJSDateTime(determiningTime) {
      const selectingDateyear = this.selectingDate.slice(0, 4);
      const selectingDatemonthIndex = this.selectingDate.slice(5, 7) - 1;
      const selectingDateday = this.selectingDate.slice(8, 10);

      let [hour, minute] = determiningTime.slice(0, -3)
          .split(':')
          .map(Number);

      const amPM = determiningTime.slice(-2);

      if (amPM === 'PM' && hour !== 12) {
        hour += 12;
      }

      determiningTime = new Date(selectingDateyear, selectingDatemonthIndex, selectingDateday, hour, minute);
      determiningTime = determiningTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });

      return this.getJSTime(determiningTime);
    },

    getDayNameOfTheWeek() {
      const day = new Date();
      day.setDate(+`${this.selectingDate.split('-')[2]}`);
      day.setMonth(+`${this.selectingDate.split('-')[1]}` - 1);
      day.setFullYear(+`${this.selectingDate.split('-')[0]}`);
      return day.toLocaleDateString('en-US', { weekday: 'long' })
          .toLowerCase();
    },

    didPassCurrentTime(determiningTime) {
      // console.log(`\nDetermining time: ${determiningTime}`);
      // console.log('Check current time');
      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);
      let currentTime = new Date();
      return currentTime > determiningJSTime;
    },

    isStoreBusinessHours(determiningTime) {
      // console.log('Check store business hours');

      const dayName = this.getDayNameOfTheWeek();

      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);

      let startTime = this.getJSTime(this.store['business_hours'][dayName]['start']);

      let endTime = this.getJSTime(this.store['business_hours'][dayName]['end']);

      if (this.store['business_hours'][dayName]['is_closed']) {
        return false;
      } else if (determiningJSTime >= startTime && determiningJSTime < endTime) {
        return true;
      } else {
        return false;
      }
    },

    isStaffWorkingHour(determiningTime) {
      // console.log('Check staff working hours');
      let result = false;
      const dayName = this.getDayNameOfTheWeek();

      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);

      if (this.selectingStaffs.length === 0) {
        // Make the time slot available if no staff is selected
        return true;
      } else {
        for (let i = 0; i < this.selectingStaffs[0]['staff_working_hours'][dayName].length; i++) {

          let staffStartTime = this.getJSTime(this.selectingStaffs[0]['staff_working_hours'][dayName][i]['start']);
          let staffEndTime = this.getJSTime(this.selectingStaffs[0]['staff_working_hours'][dayName][i]['end']);

          if (determiningJSTime >= staffStartTime && determiningJSTime < staffEndTime) {
            result = true;
            break;
          }
        }
      }

      return result;
    },

    isStoreTimeOff(determiningTime) {
      // console.log('Check store time off');
      let result = false;
      let reason = '';
      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);

      this.time_offs.forEach((time_off) => {
            if (time_off['is_store_time_off']) {
              let timeOffStartTime = new Date(time_off['start_time']);
              let duration = time_off['duration'];
              let timeOffEndTime = new Date(timeOffStartTime.getTime() + duration * 60000);

              if (determiningJSTime >= timeOffStartTime && determiningJSTime <= timeOffEndTime) {
                result = true;
                reason = time_off['note'];
              }
            }
          }
      );
      return [result, reason];
    },

    isStaffTimeOff(determiningTime) {
      console.log('Check staff time off');
      let result = false;
      let reason = '';
      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);
      // console.log(`Determining time: ${determiningJSTime}`);

      if (this.selectingStaffs.length === 0) {
        // Make the time slot available if no staff is selected
        return true;
      } else {
        this.time_offs.forEach((time_off) => {
              if (time_off['is_time_off'] && !time_off['is_store_time_off'] && time_off['employee_ids'][0] === this.selectingStaffs[0]['id']) {
                // console.log(`Time off start time in json: ${time_off['start_time']}`);
                let timeOffStartTime = new Date(time_off['start_time']);
                // console.log(`Time off start time: ${timeOffStartTime}`);
                let duration = time_off['duration'];
                let timeOffEndTime = new Date(timeOffStartTime.getTime() + duration * 60000);
                // console.log(`Time off end time: ${timeOffEndTime}`);

                // this.staffTimeOffStart = timeOffStartTime; // For use at isEnoughTime()
                // console.log(`->Staff time off start: ${this.staffTimeOffStart}`);

                if (determiningJSTime >= timeOffStartTime && determiningJSTime <= timeOffEndTime) {
                  result = true;
                  reason = time_off['note'];
                }
              }
            }
        );
      }
      return [result, reason];
    },

    isStaffAvailable(determiningTime) {
      // console.log('Check staff availability');
      let result = true;
      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);

      if (this.selectingStaffs.length === 0) {
        // Make the time slot available if no staff is selected
        // console.log('No staff selected');
        return true;
      } else {
        this.appointments.forEach((appointment) => {
              if (appointment['employee_ids'][0] === this.selectingStaffs[0]['id'] && appointment['is_canceled'] === false) {
                let appointmentStartTime = new Date(appointment['start_time']);
                let duration = appointment['duration'];
                let appointmentEndTime = new Date(appointmentStartTime.getTime() + duration * 60000);

                if (determiningJSTime >= appointmentStartTime && determiningJSTime <= appointmentEndTime) {
                  result = false;
                }
              }
            }
        );
      }
      return result;
    },

    isEnoughTime(determiningTime) {
      // return true
      console.log(`+++++++++++++++++++++++++++++++Check if there is enough time for ${determiningTime}`);
      let result = true;
      let determiningJSTime = this.convertDeterminingTimeToJSDateTime(determiningTime);

      console.log(`Determining time: ${determiningJSTime}`);

      if (this.selectingStaffs.length === 0) {
        // Make the time slot available if no staff is selected
        return true;
      } else if (this.selectingServices.length === 0) {
        // Make the time slot available if no service is selected
        return true;
      } else {
        let servicesDuration = 0;
        // Calculate the total duration of the selected services
        this.selectingServices.forEach((service) => {
          servicesDuration += service['duration'];
        });

        servicesDuration -= 1; // Add 1 minute buffer to avoid blocking the immediately next time slot

        let employeeAppointments = this.appointments.filter(appointment => appointment['employee_ids'][0] === this.selectingStaffs[0]['id'] && appointment['is_canceled'] === false);

        let predictedEndTime = new Date(determiningJSTime.getTime() + servicesDuration * 60000);

        let appointmentStartRightAfterDeterminingTime = new Date(determiningJSTime.getTime() + 12 * 60 * 60000);

        for (let i = 0; i < employeeAppointments.length; i++) {
          let otherAppointmentStartTime = new Date(employeeAppointments[i]['start_time']);

          if (otherAppointmentStartTime > determiningJSTime) {
            appointmentStartRightAfterDeterminingTime = otherAppointmentStartTime;
            break;
          }
        }

        if (predictedEndTime >
            appointmentStartRightAfterDeterminingTime) {
          result = false;
        }

        // Determine if the time slot is enough for the selected services before closing time
        if (this.store['has_hard_closing_time']) {
          let dayName = this.getDayNameOfTheWeek();
          let storeEndTime = this.getJSTime(this.store['business_hours'][dayName]['end']);
          if (predictedEndTime > storeEndTime) {
            result = false;
          }
        }
      }
      return result;
    },

    formatTime(time) {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      // console.log(new Intl.DateTimeFormat('en-US', options).format(time));
      return new Intl.DateTimeFormat('en-US', options).format(time);
    },

    handleButtonClick(time) {
      // Handle button click for the selected time
      console.log(`Button clicked for time: ${time}`);
      this.$emit('selectTime', time);
    },

    handleDateChange() {
      console.log(`Date changed to: ${this.date}`);
      // this.testStartTime = new Date(`${this.selectingDate} ${this.selectingTime}`);
      this.$emit('selectDate', this.date);
    },

    switchAnyAvailableTime() {
      console.log('DateTimeTab: Select any time');
      this.$emit('switchAnyAvailableTime', this.isAnyAvailableTime);
    },
  },
};
</script>
